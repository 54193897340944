import get from "lodash/get"; // 後でlodash剥がしたい
import {
  FieldPath,
  FieldValues,
  useFormState,
  UseFormStateProps,
} from "react-hook-form";
import ErrorMessage from "../../../uiParts/feedback/ErrorMessage";
import { FormField, FormFieldProps } from "./base";

type RHFFormFieldProps<FV extends FieldValues> = {
  prefixs?: Partial<Record<FieldPath<FV>, string>>;
} & FormFieldProps &
  UseFormStateProps<FV>;

export const RHFFormField = function <FV extends FieldValues>({
  prefixs,
  name,
  control,
  children,
  ...props
}: RHFFormFieldProps<FV>) {
  const { errors } = useFormState<FV>({ name, control });

  const nameArr = name ? (Array.isArray(name) ? name : [name]) : [];

  const generateMessage = (prefix: string, errorMessage: string): string => {
    return prefix ? `${prefix}: ${errorMessage}` : errorMessage;
  };

  const errorMessages = nameArr.reduce<string[]>((result, name) => {
    if (nameArr.length === 0) return [];
    const error = get(errors, name);

    if (error) {
      result.push(
        generateMessage(
          get(prefixs, name, ""),
          error?.message?.toString() || "",
        ),
      );
    }

    return result;
  }, []);

  return (
    <FormField {...props}>
      {children}
      {errorMessages.map((errorMessage, index) => (
        <ErrorMessage key={index}>{errorMessage}</ErrorMessage>
      ))}
    </FormField>
  );
};

import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { FC, ReactNode } from "react";
import { Color, Spacing } from "../../../../tokens";

type Props = {
  children: ReactNode;
};

export const ModalHeader: FC<Props> = ({ children }) => {
  return (
    <div css={modalHeaderStyle}>
      <div css={modalHeaderInnerStyle}>{children}</div>
    </div>
  );
};

const modalHeaderStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing[4]} ${Spacing[16]};
  border-bottom: 1px solid ${Color.Neutral.Light.Secondary};
  min-height: 56px;
  font-size: ${text.size[16]};
  font-weight: bold;
`;

const modalHeaderInnerStyle = css``;

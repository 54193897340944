import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React from "react";
import { Color, LineHeight, RadiusSize, Spacing } from "../../../../tokens";

type Props = {
  size: "Small" | "Medium";
  children: React.ReactNode;
  color: React.CSSProperties["color"];
  className?: string;
  isOutlined?: boolean;
};

const TextSize: { [key: string]: React.CSSProperties["fontSize"] } = {
  Small: text.size[10],
  Medium: text.size[12],
};

const TagLabel: React.FC<Props> = ({
  size,
  children,
  color,
  className,
  isOutlined,
}) => {
  return (
    <span
      css={tagLabel(TextSize[size], color, isOutlined)}
      className={className}
    >
      {children}
    </span>
  );
};

const tagLabel = (
  size: React.CSSProperties["fontSize"],
  color: React.CSSProperties["color"],
  isOutlined?: boolean,
) => css`
  display: inline-block;
  color: ${isOutlined ? color : Color.White};
  background: ${isOutlined ? Color.White : color};
  padding: ${Spacing[2]} ${Spacing[4]};
  border: solid 1px ${color};
  border-radius: ${RadiusSize[2]};
  font-size: ${size};
  font-weight: bold;
  text-align: center;
  line-height: ${LineHeight[100]};
`;

export default TagLabel;

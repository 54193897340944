import { css, keyframes } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, MouseEvent, ReactNode, useCallback, useState } from "react";
import { Color, ZIndex } from "../../../../tokens";

type Props = {
  children: ReactNode;
  className?: string;
  isDisplayed?: boolean;
  onClose?: (event?: MouseEvent<HTMLElement>) => void;
  onCloseAfter?: () => void;
};

export const Modal: FC<Props> = ({
  children,
  className,
  isDisplayed,
  onClose,
  onCloseAfter,
}) => {
  const [isRendered, setIsRendered] = useState(false);

  const onAnimationEnd = useCallback(() => {
    if (isDisplayed) {
      setIsRendered(true);
    } else {
      setIsRendered(false);
      onCloseAfter?.();
    }
  }, [isDisplayed, onCloseAfter]);

  return isDisplayed || isRendered ? (
    <div css={modalStyle}>
      {(isDisplayed || isRendered) && (
        <div
          css={[
            modalOverlayStyle,
            isDisplayed
              ? modalOverlayWithOpenStyle
              : modalOverlayWithCloseStyle,
          ]}
          onClick={onClose}
        ></div>
      )}
      <div
        css={[
          modalMainStyle,
          isDisplayed ? modalMainWithOpenStyle : modalMainWithCloseStyle,
        ]}
        className={className}
        onAnimationEnd={onAnimationEnd}
      >
        {isDisplayed && isRendered && (
          <div css={modalMainCloseStyle}>
            <button
              type="button"
              onClick={onClose}
              css={modalMainCloseButtonStyle}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        )}
        <div css={modalContentStyle}>{children}</div>
      </div>
    </div>
  ) : null;
};

const modalMainOpenKeyFrames = keyframes`
  100% {
    transform: translateX(0);
  }
`;

const modalMainCloseKeyFrames = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const modalOverlayOpenKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const modalOverlayCloseKeyFrames = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const modalStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${ZIndex.Modal};
`;

const modalOverlayStyle = css`
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: rgba(0, 0, 0, 0.6);
  animation: 0.2s ease-in-out forwards;
`;

const modalOverlayWithOpenStyle = css`
  animation-name: ${modalOverlayOpenKeyFrames};
`;

const modalOverlayWithCloseStyle = css`
  animation-name: ${modalOverlayCloseKeyFrames};
`;

const modalMainStyle = css`
  position: relative;
  width: 50%;
  min-width: 480px;
  height: inherit;
  background-color: ${Color.White};
  transform: translateX(-100%);
  animation: 0.3s ease-in-out forwards;
`;

const modalMainWithOpenStyle = css`
  animation-name: ${modalMainOpenKeyFrames};
`;

const modalMainWithCloseStyle = css`
  animation-name: ${modalMainCloseKeyFrames};
`;

const modalMainCloseStyle = css`
  position: absolute;
  top: 8px;
  right: -32px;
`;

const modalMainCloseButtonStyle = css`
  padding: 0;
  width: 32px;
  height: 32px;
  color: ${Color.White};
  font-size: ${text.size[24]};
  text-align: center;
`;

const modalContentStyle = css`
  overflow-y: auto;
  overscroll-behavior-y: none;
  height: inherit;
`;

import { AnyVariables, TypedDocumentNode, OperationContext } from "@urql/core";
import { DocumentNode } from "graphql";
import { useMutation, UseMutationResponse } from "urql";

export const useMutationWithError = <
  // ライブラリの型に合わせるためにanyを使う
  Data = any, // eslint-disable-line @typescript-eslint/no-explicit-any
  Variables extends AnyVariables = AnyVariables,
>(
  query: DocumentNode | TypedDocumentNode<Data, Variables> | string,
): UseMutationResponse<Data, Variables> => {
  const [result, executeMutation] = useMutation<Data, Variables>(query);
  return [
    result,
    (variables: Variables, context?: Partial<OperationContext>) =>
      executeMutation(variables, context).then((result) => {
        if (result.error) {
          throw result.error.message;
        }
        return result;
      }),
  ];
};

import { css, SerializedStyles } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React from "react";
import { Color, Spacing, RadiusSize } from "../../../../tokens";

export type Props = {
  isOpen?: boolean;
  children: React.ReactNode;
  type: keyof typeof Type;
  className?: string;
};

const Type = {
  Success: css`
    background: ${Color.Attention.Success.Pale};
    border: 1px solid ${Color.Attention.Success.Light};
  `,
  Caution: css`
    background: ${Color.Attention.Caution.Pale};
    border: 1px solid ${Color.Attention.Caution.Light};
  `,
  Info: css`
    background: ${Color.Primary.Pale};
    border: 1px solid ${Color.Primary.Light};
  `,
};

const Alert: React.FC<Props> = ({ children, type, className }) => {
  return (
    <div css={wrapperStyle(Type[type])} className={className}>
      {children}
    </div>
  );
};

const wrapperStyle = (styledByType: SerializedStyles) => css`
  width: 100%;
  padding: ${Spacing[8]};
  ${styledByType}
  border-radius: ${RadiusSize[4]};
  font-size: ${text.size[14]};
`;

export default Alert;

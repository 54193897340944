import { css, jsx } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import {
  ComponentProps,
  FC,
  FormEventHandler,
  FormHTMLAttributes,
  ReactElement,
  useEffect,
  useRef,
} from "react";
import { useMemo } from "react";
import { Color, Spacing, ZIndex } from "../../../../tokens";
import Alert from "../../feedback/Alert";
import LoadingHandler from "../../feedback/LoadingHandler";
import { ActionButton } from "../../trigger/ActionButton";
import { ModalHeader } from "../ModalHeader";

type ErrorMessageProps = {
  errors?: string[];
  hasSubmittingError?: boolean;
  isErrorsIntoView?: boolean;
};

const ErrorMessage: FC<ErrorMessageProps> = ({
  errors,
  hasSubmittingError,
  isErrorsIntoView,
}) => {
  const errorMessageRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (!isErrorsIntoView) {
      return;
    }

    if (!errorMessageRef.current) {
      return;
    }

    errorMessageRef.current.scrollIntoView({
      behavior: "auto",
    });
  }, [isErrorsIntoView]);

  return (
    <Alert type="Caution">
      <ul ref={errorMessageRef}>
        {errors?.map((error) => {
          return <li key={error}>{error}</li>;
        })}
        {hasSubmittingError && (
          <li>
            保存に失敗しました。通信環境を確認の上、もう一度お試しください。
          </li>
        )}
      </ul>
    </Alert>
  );
};

type ChildType<T = Record<string, unknown>> =
  | ReactElement<T>
  | EmotionJSX.Element
  | string
  | boolean
  | null
  | undefined;

type ChildrenType =
  | ChildType<{ children: ChildType }>
  | [ChildType<{ children: ChildType }>, ChildType<{ children: ChildType }>];

type AsFormProps = {
  as?: "form";
  action?: FormHTMLAttributes<HTMLFormElement>["action"];
  method?: FormHTMLAttributes<HTMLFormElement>["method"];
  onSubmit: FormEventHandler<HTMLFormElement>;
};

type AsDivProps = {
  as: "div";
  action?: never;
  method?: never;
  onSubmit?: never;
};

type BaseProps = Omit<ComponentProps<typeof ModalHeader>, "children"> &
  ErrorMessageProps & {
    heading: string;
    children: ChildrenType;
    notices?: string[];
    isSubmitting?: boolean;
    isLoading?: boolean;
    className?: string;
  };

type Props = BaseProps & (AsFormProps | AsDivProps);

export const ModalForm: FC<Props> = ({
  as = "form",
  action = "*",
  onSubmit,
  method,
  children,
  heading,
  isSubmitting,
  isLoading,
  hasSubmittingError,
  errors,
  isErrorsIntoView,
  notices,
  className,
}) => {
  const rootAttributes = useMemo(() => {
    if (as !== "form") {
      return undefined;
    }

    return {
      onSubmit,
      action,
      method,
    };
  }, [as, onSubmit, action, method]);

  return jsx(
    as,
    { css: modalFormStyle, ...rootAttributes },
    <>
      <div css={modalFormHeaderStyle}>
        <ModalHeader>{heading}</ModalHeader>
      </div>
      <div css={modalFormMainStyle} className={className}>
        {((errors && errors.length > 0) || hasSubmittingError) && (
          <div css={modalFormMainContentStyle}>
            <ErrorMessage
              errors={errors}
              hasSubmittingError={hasSubmittingError}
              isErrorsIntoView={isErrorsIntoView}
            />
          </div>
        )}
        {notices && notices.length > 0 && (
          <div css={modalFormMainContentStyle}>
            <Alert type="Info">
              <ul>
                {notices?.map((notice) => {
                  return <li key={notice}>{notice}</li>;
                })}
              </ul>
            </Alert>
          </div>
        )}
        <div css={modalFormMainContentStyle}>
          {Array.isArray(children) ? children[0] : children}
        </div>
      </div>
      <div css={modalFormFooterStyle}>
        {Array.isArray(children) ? (
          children[1]
        ) : (
          <ActionButton
            type="submit"
            label={isSubmitting ? "保存中..." : "保存する"}
            kind="Primary"
            isDisabled={isSubmitting}
            css={formSubmitButtonStyle}
          />
        )}
      </div>
      {isLoading && <LoadingHandler css={formLoadingStyle} isLoading />}
      {isSubmitting && <LoadingHandler css={formSubmittingStyle} isLoading />}
    </>,
  );
};

const modalFormStyle = css`
  display: flex;
  flex-direction: column;
  height: inherit;
`;

const modalFormHeaderStyle = css`
  flex-shrink: 1;
`;

const modalFormMainStyle = css`
  overflow-y: auto;
  flex-grow: 1;
  padding: ${Spacing[16]};
`;
const modalFormMainContentStyle = css`
  & + & {
    margin-top: ${Spacing[16]};
  }
`;

const modalFormFooterStyle = css`
  flex-shrink: 1;
  padding: ${Spacing[16]};
  border-top: 1px solid ${Color.Neutral.Light.Secondary};
`;

const formSubmitButtonStyle = css`
  width: 100%;
`;

const formLoadingStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Color.White};
  z-index: ${ZIndex.Modal};
`;

const formSubmittingStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Color.Opacity[40]};
  z-index: ${ZIndex.Modal};
`;

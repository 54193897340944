import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Spacing, Color, LineHeight } from "../../../../tokens";
import TagLabel from "../../../uiParts/dataDisplay/TagLabel";
import ErrorMessage from "../../../uiParts/feedback/ErrorMessage";
import Tooltip from "../../dataDisplay/Tooltip";
import { TooltipDisplay } from "../../dataDisplay/TooltipDisplay";

export type FormFieldProps = {
  heading?: string;
  supplement?: string;
  children: React.ReactNode;
  isRequired?: boolean;
  isConfidential?: boolean;
  errorMessage?: string;
  testId?: string;
};

export const FormField: React.FC<FormFieldProps> = ({
  heading,
  supplement,
  children,
  isRequired,
  isConfidential,
  errorMessage,
  testId,
}) => {
  return (
    <div css={formFieldStyle} data-testid={testId}>
      {heading && (
        <p css={headingStyle}>
          <span>
            <span>{heading}</span>
            {supplement && <span css={supplementStyle}>{supplement}</span>}
          </span>
          {isRequired && (
            <TagLabel
              size="Small"
              css={labelStyle}
              color={Color.Attention.Caution.Base}
            >
              必須
            </TagLabel>
          )}
          {isConfidential && (
            <TooltipDisplay
              css={labelStyle}
              spacing={Spacing[4]}
              horizontalPosition="anchor-left"
              verticalPosition="bottom"
            >
              <TagLabel size="Small" color={Color.Data.Gray.Primary} isOutlined>
                非公開 <FontAwesomeIcon icon={faInfoCircle} />
              </TagLabel>
              <Tooltip>仲介事業者に非公開の項目です</Tooltip>
            </TooltipDisplay>
          )}
        </p>
      )}
      {children}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

const formFieldStyle = css`
  > *:not(:first-of-type) {
    margin-top: ${Spacing[8]};
  }
`;

const headingStyle = css`
  display: flex;
  align-items: center;
  font-size: ${text.size[14]};
  font-weight: bold;
  line-height: ${LineHeight[140]};
  margin-bottom: ${Spacing[8]};
`;

const supplementStyle = css`
  font-size: ${text.size[10]};
  margin-left: ${Spacing[2]};
`;

const labelStyle = css`
  margin-left: ${Spacing[8]};
`;

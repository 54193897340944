import { css } from "@emotion/react";
import React from "react";
import { Spacing, ZIndex } from "../../../../tokens";

type TooltipHorizontalPosition =
  | "left"
  | "right"
  | "center"
  | "anchor-left"
  | "anchor-right";
type TooltipVerticalPosition =
  | "top"
  | "bottom"
  | "center"
  | "anchor-top"
  | "anchor-bottom";

type Props = {
  className?: string;
  children: [JSX.Element, JSX.Element];
  horizontalPosition: TooltipHorizontalPosition;
  verticalPosition: TooltipVerticalPosition;
  spacing?: string;
};

export const TooltipDisplay: React.FC<Props> = ({
  children,
  horizontalPosition,
  verticalPosition,
  spacing,
  className,
}) => {
  return (
    <div css={tooltipDisplayStyle} className={className}>
      <div css={tooltipDisplayTriggerStyle}>{children[0]}</div>
      <div
        css={tooltipDisplayContentStyle(
          horizontalPosition,
          verticalPosition,
          spacing ?? Spacing[4],
        )}
      >
        {children[1]}
      </div>
    </div>
  );
};

const tooltipDisplayStyle = css`
  display: inline-block;
  position: relative;

  &:hover {
    > * {
      display: inline-block;
    }
  }
`;

const tooltipDisplayTriggerStyle = css`
  display: inline-block;
`;

const tooltipDisplayContentStyle = (
  horizontal: TooltipHorizontalPosition,
  vertical: TooltipVerticalPosition,
  spacing: string,
) => {
  const transforms = [
    horizontal === "left" && "translateX(-100%)",
    horizontal === "right" && "translateX(100%)",
    horizontal === "center" && "translateX(-50%)",
    vertical === "top" && "translateY(-100%)",
    vertical === "bottom" && "translateY(100%)",
    vertical === "center" && "translateY(-50%)",
  ]
    .filter((transform) => typeof transform === "string")
    .join(" ");
  return css`
    display: none;
    position: absolute;
    white-space: nowrap;
    z-index: ${ZIndex.Balloon};
    ${horizontal === "left" ? `left: -${spacing};` : ""}
    ${horizontal === "right" ? `right: -${spacing};` : ""}
      ${horizontal === "center" ? "left: 50%;" : ""}
      ${horizontal === "anchor-left" ? "left: 0;" : ""}
      ${horizontal === "anchor-right" ? "right: 0;" : ""}
      ${vertical === "top" ? `top: -${spacing};` : ""}
      ${vertical === "bottom" ? `bottom: -${spacing};` : ""}
      ${vertical === "center" ? "top: 50%;" : ""}
      ${vertical === "anchor-top" ? "top: 0;" : ""}
      ${vertical === "anchor-bottom" ? "bottom: 0;" : ""}
      ${transforms !== "" ? `transform: ${transforms};` : ""};
  `;
};

import { ComponentProps, FC } from "react";
import ClientOnlyPortal from "../../components/helpers/ClientOnlyPortal";
import { Modal as ModalComponent } from "../../components/uiParts/overlays/Modal";
import { MODAL_ID } from "./constants";
import { useModalContext } from "./context";

type Props = {
  id: symbol;
} & Omit<ComponentProps<typeof ModalComponent>, "isDisplayed" | "onClose">;

export const Modal: FC<Props> = ({ id, ...props }) => {
  const { currentId, closeModal, isActive, closeAfter } = useModalContext();

  return currentId === id || isActive ? (
    <ClientOnlyPortal selector={`#${MODAL_ID}`}>
      <ModalComponent
        {...props}
        isDisplayed={currentId === id}
        onClose={closeModal}
        onCloseAfter={() => {
          closeAfter();
          props.onCloseAfter?.();
        }}
      >
        {props.children}
      </ModalComponent>
    </ClientOnlyPortal>
  ) : null;
};

import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Color, Spacing, LineHeight } from "../../../../tokens";

type Props = {
  children?: React.ReactNode;
};
const ErrorMessage: FC<Props> = ({ children }) => {
  if (!children) return null;

  return (
    <p css={errorStyle}>
      <FontAwesomeIcon icon={faExclamationCircle} css={iconStyle} />
      {children}
    </p>
  );
};

const errorStyle = css`
  display: flex;
  align-items: start;
  color: ${Color.Attention.Caution.Base};
  font-size: ${text.size[14]};
  line-height: ${LineHeight[140]};
`;

const iconStyle = css`
  margin-right: ${Spacing[4]};
  // 1行目の文言の中央揃えにする
  // 計算式は (lineHeight - 1) * 0.5
  padding-top: 0.2em;
`;

export default ErrorMessage;
